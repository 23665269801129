// Import necessary modules and components
import React, { useEffect, useState } from "react";
import useInterval from "../customHooks/useInterval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/styles/notification.css";
import {
  faCalendar,
  faImage,
  faUser,
  faClock,
  faAt,
  faSatelliteDish,
  faTriangleExclamation,
  faBoxArchive,
  faBoxOpen,
  faTimes,
  faStopwatch,
  faLocationDot,
  faPersonWalking
} from "@fortawesome/free-solid-svg-icons";

import b_image1 from "../utils/assets/beacon1.jpeg";
import b_image2 from "../utils/assets/beacon2.jpeg";
import b_image3 from "../utils/assets/beacon3.jpeg";
import Modal from "react-modal";
import Endpoints from "../utils/repository/Endpoints";
import { SmsPostRequest } from "../utils/repository/RequestMaker";
import { getBeaconImages } from "../utils/repository/RequestMaker"

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Define the Notification component that takes several props as input
const Notification = ({
  isNew,
  alarmOn,
  name,
  email,
  alertType,
  position,
  time,
  uniqueId,
  major,
  minor,
  proximity,
  txPower,
  batteryLevel,
  deviceType,
  userMotion,
  rssi,
  location,
  beaconId,
  locationMap
}) => {
  // Define several state variables to control the appearance and behavior of the Notification element
  // Define state variables for managing the appearance and behavior of the notification
  const [boxStyle, setBoxStyle] = useState({}); // CSS styles for the notification box
  const [feildStyle, setFeildStyle] = useState({}); // CSS styles for the field badges
  const [timeDiffernce, setTimeDiffernce] = useState(0); // Time difference between notification time and current time
  const [isArchived, setIsArchived] = useState(false); // Whether the notification is archived or not
  const [approved, setApproved] = useState(false); // Whether the notification has been approved or not
  const [showBeaconData, setShowBeaconData] = useState(false); // Whether to show the beacon data or not
  const [isNewNotification, setIsNewNotification] = useState(false); // Whether the notification is new or not (based on time difference)
  const [modalIsOpen, setIsOpen] = React.useState(false);


  // show Beacon Images
  const [images, setImages] = useState([]);


  /**
   * Function to check current time 
   */

  const isWithinTimeRange = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    // console.log('Current hour ', currentHour)
  
    // Check if current hour is between 17:00 (5:00 PM) and 05:00 (5:00 AM)
    return currentHour >= 17 || currentHour < 5;
  };
  /**
   * Opens the modal to show Images 
   */
  function openModal() {
    console.log("beacon id is ", beaconId);
    setIsOpen(true);
    getBeaconImages(beaconId, setImages);
  }


  // Function to decode base64 string to image URL
  function decodeBase64ToImage(base64String) {
    return `data:image/png;base64,${base64String}`;
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  // Define constants to determine the age of the Notification element
  const NOTIFICATION_NEW = 30;
  const NOTIFICATION_OLD = 60;

  // The useInterval hook to update the time difference between the current time and the 
  // time the notification was received
  useInterval(() => {
    const givenTime = new Date(time);
    const currentTime = new Date();
    const difference = currentTime - (givenTime + 1000);
    setTimeDiffernce(difference / 1000);
  }, 1000);

  // Define functions to handle archiving and unarchiving of the Notification element
  const handleArchiving = () => {
    setIsArchived(true);
  };

  const handleUnArchiving = () => {
    setIsArchived(false);
  };

  const openMap = () => {
    window.open(locationMap, '_blank');
  };

  // Counter Functionality to Stop it
    // testing purposes set counter to 10 seconds
   const [counter, setCounter] = useState(60);
  //const [counter, setCounter] = useState(10);
  const [counterActive, setCounterActive] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  // const [isNotificationActive, setIsNotificationActive] = useState(false);

  const stopCounter = () => {
    setCounterActive(false);
    setHasNotification(false); // Reset hasNotification when counter stops
  };

  useEffect(() => {

    // console.log("counterActive?? ", counterActive);
    if (counter > 0 && counterActive) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      if (hasNotification) {
        stopCounter();
        setHasNotification(false);
      }
     setCounterActive(false);
    }
  }, [counter, counterActive, hasNotification]);


  const formatCounter = (value) => {
    if (value === 0) {
      return "0:00";
    } else {
      const minutes = Math.floor(value / 60);
      const seconds = value % 60;

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      return `${formattedMinutes}:${formattedSeconds}`;
    }
  };

  /**
   * changes have been done here for time 
   */

  useEffect(() => {
    // console.log("isNew ", isNew);
    // console.log("time ", time)

    if (isNew && isWithinTimeRange()) {
    //  if (!isNotificationActive) {
       // setIsNotificationActive(true);
        setCounterActive(true);
        setHasNotification(true);
     // }
    } else {
      // Reset states if the notification is not new
    //  setIsNotificationActive(false);
      setCounterActive(false);
      setHasNotification(false);
      setCounter(60); // Reset the counter value to its initial state
    }
  }, [isNew]);


  useEffect(() => {
   if (isWithinTimeRange()){
      if (counter === 1 && counterActive && alarmOn.alarmOn && !smsSent) {
        //change the text to be sent as an SMS
        /**
         * PnaKwpAl - Alert
         * PnaKwpSt - Störung
         */
        SmsPostRequest(Endpoints.send_sms, "PnaKwpAl")
          .then(() => {
            setCounterActive(false);
            setSmsSent(true);
            console.log("Message sent successfully");
          })
          .catch((error) => {
            console.log("Error in API call:", error);
          });
      }
   }
   if (!isWithinTimeRange()){
      if (isNew && alarmOn.alarmOn) {

        // change the text to be sent as an SMS
  
        /**
         * PnaKwpAl - Alert
         * PnaKwpSt - Störung
         */
        SmsPostRequest(Endpoints.send_sms, "PnaKwpAl")
          .then(() => {
            setCounterActive(false);
            setSmsSent(true);
            console.log("Message sent successfully");
          })
          .catch((error) => {
            console.log("Error in API call:", error);
          });
      }
    }
    //console.log("alarmOn ", alarmOn.alarmOn);

    if (counter === 0 && counterActive && !alarmOn.alarmOn) {
      setCounterActive(false);
    }
  }, [counter, counterActive, alarmOn.alarmOn, smsSent, isNew]);

  // Second useEffect for the second if condition
// useEffect(() => {
//   if (!isWithinTimeRange()  && isNew) {
//     console.log("smsSent?  ", smsSent);
//     console.log("isNew?  ", isNew)

//     SmsPostRequest(Endpoints.send_sms, "PnaKwpAl")
//       .then(() => {
//         setCounterActive(false);
//         setSmsSent(true);
//         console.log("Message sent successfully");
//       })
//       .catch((error) => {
//         console.log("Error in API call:", error);
//       });
//   }
// }, [smsSent]);

  // Use the useEffect hook to determine the color and style of the Notification element based on its age
  useEffect(() => {
    if (timeDiffernce < NOTIFICATION_NEW && !approved) {
      setBoxStyle({
        color: "red",
        border: "red",
        boxShadow:
          "rgba(241, 33, 8, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
      });
      setFeildStyle({
        border: "1px solid red",
        color: "red",
      });
      setIsNewNotification(true);
    } else if (
      timeDiffernce > NOTIFICATION_NEW &&
      timeDiffernce < NOTIFICATION_OLD &&
      !approved
    ) {
      setBoxStyle({
        color: "orange",
        border: "orange",
        boxShadow:
          "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
      });
      setFeildStyle({
        border: "1px solid orange",
        color: "orange",
      });
      setIsNewNotification(false);
    } else {
      setBoxStyle({
        color: "blue",
        border: "blue",
        boxShadow:
          "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
      });
      setFeildStyle({
        border: "1px solid blue",
        color: "blue",
      });
      setIsNewNotification(false);
    }
  }, [timeDiffernce]);

  // If the Notification element is archived, return a button to unarchive it
  if (isArchived) {
    return (
      <div className="col-md-4" style={{ marginTop: 15 }}>
        <span onClick={handleUnArchiving}>
          <InfoBadge
            title={""}
            value={"UnArchive"}
            icon={faBoxOpen}
            showColon={false}
          />
        </span>
      </div>
    );
  }

  // Otherwise, return the Notification element
  return (
    <>
      <div className="col-md-4">
        <div className="card d-flex" style={{ ...style.box, ...boxStyle }}>
          <div
          // className="d-flex flex-wrap div_for_userdata"
          // style={{
          //   backgroundColor: "white",
          //   padding: 10,
          //   marginTop: 10,
          // }}
          >
            {/* Use the InfoBadge component to display user information */}
            <InfoBadge
              title={"Benutzer"}
              value={name}
              icon={faUser}
              backgroundColor={"#2A2E41"}
              color="white"
            />
            {/* <InfoBadge title={"Email"} value={email} icon={faAt} /> */}
          </div>

          <div>
            {/* <div className="d-flex flex-wrap justify-content-end"> */}
            {/* Use the InfoBadge component to display the alert type */}
            {/* {!userMotion && ( */}
              {/* <InfoBadge
                title={"Meldungstyp"}
                value={alertType==='SOS Emergency Alert'? ' SOS gedrückt':alertType}
                icon={faTriangleExclamation}
                backgroundColor={isNewNotification ? "#F12108" : "#2A2E41"}
                color="#FFFFFF"
              /> */}

            <InfoBadge
                title={"Meldungstyp"}
                value={userMotion==='Stationäre'? ' Ruhealarm':
                userMotion==='Bewegungslos'? ' Ruhealarm':
                alertType === 'SOS Emergency Alert'? 'Manueller Alarm':
                alertType === 'Manuell'? 'Manueller Alarm':






                alertType}
                icon={faTriangleExclamation}
                backgroundColor={isNewNotification ? "#F12108" : "#2A2E41"}
                color="#FFFFFF"
              />
           
          </div>

          {/* {userMotion && (
            <InfoBadge
              title={"Meldungstyp"}
              value={"Automatisch"}
              icon={faTriangleExclamation}
              backgroundColor={isNewNotification ? "#F12108" : "#2A2E41"}
              color="#FFFFFF"
            />
          )} */}

          {/* {userMotion && (
            <InfoBadge title={"Bewegung"} value={userMotion} icon={faPersonWalking} />
          )} */}
          {/* <div
            className="d-flex flex-wrap div_for_beacon_data"
            style={{
              backgroundColor: "#F8F9FA",
              padding: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
            > */}
          {/* Use the InfoBadge component to display beacon information */}
          {/* <span
              onClick={() => setShowBeaconData(!showBeaconData)}
              className="buttonCursor"
            > */}

          <InfoBadge
            title={
              position.secondHighestBeacon ? "Beacon (1/2)" : "Beacon (1/1)"
            }
            value={position.highestNumber}
            icon={faSatelliteDish}
            backgroundColor={showBeaconData ? "#DFE2E5" : "#60A8D3"}
            color={showBeaconData ? "#24262B" : "white"}
          />
          {position.secondHighestBeacon && (
            <InfoBadge
              title={"Beacon (2/2):"} 
              value={position.secondHighestBeacon}
              showColon={false}
              icon={faSatelliteDish}
              backgroundColor={showBeaconData ? "#DFE2E5" : "#60A8D3"}
              color={showBeaconData ? "#24262B" : "white"}
            />
          )}

          <div onClick={openMap}  className="buttonCursor">
            <InfoBadge
              title={"GPS"}
               value={ locationMap!=''? "Bitte anklicken, um die Koordinaten zu sehen" :"GPS nicht verfügbar"}
             // value={locationMap}
              icon={faLocationDot}
              backgroundColor={isNewNotification ? "#F12108" : "#2A2E41"}
              color="#FFFFFF"
            />
          </div>

          {/* </span> */}
          {/* {showBeaconData ? (
              <>
                <InfoBadge
                  title={"Unique ID"}
                  value={uniqueId}
                  icon={faFingerprint}
                />
                <InfoBadge title={"Major"} value={major} icon={faHashtag} />
                <InfoBadge title={"Minor"} value={minor} icon={faHashtag} />
                <InfoBadge
                  title={"Proximity"}
                  value={proximity}
                  icon={faCity}
                />
                <InfoBadge
                  title={"Transmission Power"}
                  value={txPower}
                  icon={faTowerBroadcast}
                />
                <InfoBadge
                  title={"Device Type"}
                  value={deviceType}
                  icon={faMicrochip}
                />
                <InfoBadge
                  title={"Battery Level"}
                  value={batteryLevel}
                  icon={faBattery}
                />
              </>
            ) : (
              <></>
            )} */}
          {/* </div> */}

          <div className="d-flex flex-wrap justify-content-end">
            <InfoBadge
              title={""}
              showColon={false}
              value={
                <div className="d-flex flex-column">
                  <span>{"Tag " + new Date(time).toLocaleDateString("de-DE")}</span>
                  <span>{"Uhrzeit " + new Date(time).toLocaleTimeString("de-DE")}</span>
                </div>
              }
              icon={faCalendar}
            />
          </div>



    {isWithinTimeRange() && (
      <div className="d-flex flex-wrap justify-content-end">
        <span
          onClick={stopCounter}
          className={counterActive ? "buttonCursor" : ""}
        >
          <InfoBadge
            title={counterActive ? "Stop Countdown: " : "Gesehen & Quittiert"}
            value={isNew && counterActive && <div>{formatCounter(counter)}</div>}
            icon={counterActive ? faClock : faStopwatch}
            showColon={false}
            backgroundColor={counterActive ? "#F1200A" : "#808080"}
            color="white"
          />
        </span>
        {/* Additional HTML elements can be added here */}
      </div>
    )}

          <div className="d-flex flex-wrap justify-content-end">
            {/* Use the InfoBadge component to display the Archive button */}
            <span onClick={handleArchiving} className="buttonCursor">
              <InfoBadge
                title={"Archivieren"}
                value={" "}
                icon={faBoxArchive}
                showColon={false}
                backgroundColor="#60A8D3"
                color="white"
              />
            </span>
          </div>

          <div onClick={openModal} className="buttonCursor">
            <InfoBadge
              title={"Plan und Fotos zeigen"}
              value={" "}
              icon={faImage}
              showColon={false}
              backgroundColor="#E2E000"
              color="black"
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div style={{ height: "70vh", width: "70vw" }}>
          <span onClick={closeModal}>
            <div className="d-flex justify-content-end">
              <InfoBadge
                title={"close"}
                value={" "}
                icon={faTimes}
                showColon={false}
                backgroundColor="red"
                color="white"
              />
            </div>
          </span>

          <div>
      {/* Render beacon images */}
      {images.map((image, index) => (
        <img key={index} src={decodeBase64ToImage(image.file_base64)} style={{ height: "70vh", width: "70vw" }} alt={`Image ${index}`} />
      ))}
    </div>
          {/* <div>
            <img src={b_image1} style={{ height: "60vh", width: "60vw" }} />
          </div>
          <div>
            <img src={b_image2} style={{ height: "60vh", width: "60vw" }} />
          </div>
          <div>
            <img src={b_image3} style={{ height: "60vh", width: "60vw" }} />
          </div> */}
        </div>
      </Modal>
    </>
  );
};

const style = {
  box: {
    padding: 20,
    marginTop: 20,
    flexDirection: "column",
  },
};

export default Notification;

// Define the InfoBadge component, which displays an individual badge of information
const InfoBadge = ({
  title,
  value,
  icon,
  backgroundColor = "#DFE2E5",
  color = "#24262B",
  showColon = true,
}) => {
  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{
        borderRadius: 100,
        minHeight: 30,
        padding: 10,
        color: color,
        backgroundColor: backgroundColor,
        marginTop: 4,
        marginRight: 4,
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: 20,
          width: 20,
          borderRadius: 20,
          padding: 4,
          border: "1px solid",
          borderColor: color,
          marginRight: 5,
        }}
      >
        {/* //Use the FontAwesomeIcon component to display the badge icon */}

        <FontAwesomeIcon icon={icon} style={{ fontSize: 10 }} />
      </div>
      {title ? title : ""}
      {showColon ? <span style={{ marginRight: 3 }}>:</span> : ""}
      {/* <b>{value ? value : "Kein Leuchtfeuer in der Nähe"}</b> */}
      <b>{value || ""}</b>
    </div>
  );
};
