import React, { useEffect, useState } from "react";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import Header from "./Components/Header";
import { getAlarmStatus, updateAlarmStatus } from './Components/alarmApi';

function App() {

  const [alarmOn, setAlarmOn] = useState(null);

  useEffect(() => {
   // console.log('alarmOn ', alarmOn)
    getAlarmStatus().then((status) => {
      console.log('alarm status ', status)
      setAlarmOn(status === "1");
    });
  }, []);

  const toggleAlarm = () => {
    const newAlarmStatus = !alarmOn;
    console.log('newAlarmStatus ', newAlarmStatus)
    updateAlarmStatus(newAlarmStatus ? '1' : '0').then(() => {
      setAlarmOn(newAlarmStatus);
    });
  };

  if (alarmOn === null) {
    // Handle loading state or show a spinner while fetching the initial alarm status
    return <div>Loading...</div>;
  }

  return (
    <div>
    <Header alarmOn={alarmOn} toggleAlarm={toggleAlarm} />
    <Dashboard alarmOn={alarmOn}  />
    </div>
  )
}

export default App