export default {
  api: "https://api.eye-dashboard.compliance.monster/api",
  //api:"https://api-dev.eye-dashboard.compliance.monster/api",
  send_sms:"/send-message",
  files: "/files",
  file: "/file/alerts/",
  upload_content: "/file/upload-content",
  alarm_status:"/alarm-status",
  alarm_status_update:"/update-alarm-status/",
  socket_auth_prod:"https://eon-socket.compliance.monster/auth",
  socket_auth_dev:"https://eon-socket-dev.compliance.monster/auth",
  socket_connection_prod:"wss://eon-socket.compliance.monster",
  socket_connection_dev:"wss://eon-socket-dev.compliance.monster",
  socket_connection_laravel:"wss://api.eye-dashboard.compliance.monster:6001/app/ABCDEFG_live?protocol=7&client=js&version=4.3.1&flash=fals",
  submit_socket_data:"/v1/user_motion_data"
};
