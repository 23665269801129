import axios from "axios";
import Endpoints from "./Endpoints";

export function NoAuthPostRequest(raw, path) {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(Endpoints.api + path, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      error.json().then((val) => {
        console.log(val);
        alert(val.message);
      });
    });
}

export function GetRequest(path) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(Endpoints.api + path, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      error.json().then((val) => {
        console.log(val);
        if (
          val.message === "Token mismatched or expired. Please check token!"
        ) {
          localStorage.clear();
          window.location.href = "./login";
          return;
        }
        alert(val.message);
      });
    });
}

export function GetRequestStatus(path){
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",

  };
  return fetch(Endpoints.api + path, requestOptions)
  .then(response => {
    if (response.ok) {
      // Request was successful
      return response;
    } else {
      // Request failed
      throw new Error('Request failed');
    }
  })
  .then(data => {
    // Handle the response data
    console.log("Data is ", data);
  })
  .catch(error => {
    // Handle any errors
    console.error(error);
  });
}

export function PostRequest(raw, path) {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
  };

  return fetch(Endpoints.api + path, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      error.json().then((val) => {
        console.log(val);
        alert(val.message);
      });
    });
}

export function PostRequestWithTextResponse(raw, path) {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
  };

  return fetch(Endpoints.api + path, requestOptions)
    .then(async (response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      error.json().then((val) => {
        console.log(val);
        alert(val.message);
      });
    });
}

export function PDFStreamMaker(path) {
  const fullpath = Endpoints.api + path;
  console.log(fullpath);
  return axios(fullpath, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  })
    .then((response) => {
      console.log("response", response.data.body.file_base64);
      const file = new Blob([response.data.body.file_base64], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function FormPostRequest(formdata, path) {
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return fetch(Endpoints.api + path, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}


export function SmsPostRequest(path, message){
  const requestBody = {
     receiver: '+4915167970152',
    //receiver: '+4917615418105',
    //receiver: '+491719035321', // Wicky's number for testing 
    message: message
  }
  return fetch(Endpoints.api +path, {
    method: 'POST',
    body:  JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    }
  })   .then((response) => {
    if (response.ok) {
      console.log('Message sent successfully');
    } else {
      console.log('Error sending message');
    }
  })
  .catch((error) => {
    console.log('Error in API call:', error);
  });
}

export function getBeaconImages(name, setImages){
  // const beaconID = '11s400Zc';
  //const endpoint = `https://api.eye-dashboard.compliance.monster/api/v1/get-beacon-images/${name}`;
  const endpoint = `https://api.eye-dashboard.compliance.monster/api/v1/beacon-images/${name}`;

  fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // You might need additional headers based on the API requirements
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Assuming the response is in JSON format
    })
    .then(data => {
      // Handle the data from the response
      console.log('Data:', data);
      if (data.body.images && data.body.images.length > 0) {
        const decodedImages = data.body.images.map(image => ({
          file_base64: image.file_base64
        }));
        setImages(decodedImages);
      } else {
        console.log('No images found');
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      console.error('Error:', error);
    });
  
}

