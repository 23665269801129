import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState, useEffect } from "react";
import logo from "../utils/assets/logo.png";
import { FormPostRequest, GetRequest, GetRequestStatus } from "../utils/repository/RequestMaker";
import Endpoints from "../utils/repository/Endpoints";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';


const Header = ({ alarmOn, toggleAlarm } ) => {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const [alarmOn, setAlarmOn] = useState(true);


  // useEffect(() => {
  //   // Get the initial alarm status from the backend and set it in the state
  //   getAlarmStatus().then((status) => {
  //     console.log("Current Status is ", status)
  //     setAlarmOn(status === "1");
  //   });
  // }, []);

  // const toggleAlarm = () => {
  //   const newAlarmStatus = !alarmOn;


  //   // Update the alarm status on the backend
  //   updateAlarmStatus(newAlarmStatus ? '1' : '0');

  //   // Update the state
  //   setAlarmOn(newAlarmStatus);
  // };

  const handleFileInputChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    // Do something with the selected file
    console.log("file", file);
    var formdata = new FormData();
    formdata.append("file", file);
    FormPostRequest(formdata, Endpoints.upload_content).then((res) => {
      let status = res.status;
      if (status === 200) {
        alert("Successfully uploaded " + file.name);
        console.log(res.body);
      } else {
        alert(`Upload failed: ${res.data.file[0]} ${res.message}`);
      }
      event.target.value = "";
      setIsLoading(false);
      GetRequest(Endpoints.file + "11s400Zctr921").then((val) =>
        console.log("val:", val)
      );
    });
  };
  return (
    <nav className="navbar" style={{ backgroundColor: "#F1200A" }}>
      <a className="navbar-brand">
        <img src={logo} alt="" width="50" height="50" />
        <span style={{ color: "white", marginLeft: 10 }}>EYE Alerts</span>
      </a>
      <div style={{ display: 'flex', alignItems: 'center' }}>
  <div style={{ marginRight: 10 }}>
    <button onClick={handleButtonClick} className="file-input-button">
      {isLoading ? (
        <>
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </>
      ) : (
        <>
          <FontAwesomeIcon
            icon={faAdd}
            style={{ fontSize: 10, marginRight: 10 }}
          />
          upload xlsx
        </>
      )}
    </button>
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileInputChange}
      className="file-input"
    />
  </div>
  <div style={{ marginRight: 10 }}>
    <Toggle
      onChange={() => toggleAlarm()}
      icons={false}
      checked ={alarmOn}
    />
  </div>
  <div>
  {alarmOn ? (
      <span style={{ color: 'white', fontWeight: 'bold', marginRight: '5px' }}>Alarm an</span>
    ) : (
      <span style={{ color: 'white', fontWeight: 'bold', marginRight: '5px' }}>Alarm aus</span>
    )}
  </div>
</div>

 


      {/* <div style={{ marginRight: 10 }}>
        <button onClick={handleButtonClick} className="file-input-button">
          {isLoading ? (
            <>
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faAdd}
                style={{ fontSize: 10, marginRight: 10 }}
              />
              upload xlsx
            </>
          )}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInputChange}
          className="file-input"
        />
      </div> */}
    </nav>
  );
};

export default Header;
